export default [
  // {
  //   label: "breadcrumb.about",
  //   icon: "GlobeIcon",
  //   type: "divider",
  // },
  // {
  //   key: "aboutBannerId",
  //   initKey: "aboutBanner",
  //   label: "field.aboutBanner",
  //   type: "async-single-image",
  //   path: "banner",
  //   pw: 1000,
  //   ph: 500,
  //   disableUploadHistory: true,
  //   cols: 12,
  // },
  // {
  //   label: "breadcrumb.directory",
  //   icon: "BoxIcon",
  //   type: "divider",
  // },
  // {
  //   key: "directoryBannerId",
  //   initKey: "directoryBanner",
  //   label: "field.directoryBanner",
  //   type: "async-single-image",
  //   path: "banner",
  //   pw: 1000,
  //   ph: 500,
  //   disableUploadHistory: true,
  //   cols: 12,
  // },
  // {
  //   label: "breadcrumb.contact",
  //   icon: "MailIcon",
  //   type: "divider",
  // },
  // {
  //   key: "contactBannerId",
  //   initKey: "contactBanner",
  //   label: "field.contactBanner",
  //   type: "async-single-image",
  //   path: "banner",
  //   pw: 1000,
  //   ph: 500,
  //   disableUploadHistory: true,
  //   cols: 12,
  // },
  {
    label: "breadcrumb.galleryCategory",
    icon: "ImageIcon",
    type: "divider",
  },
  {
    key: "galleryCategoryBannerId",
    initKey: "galleryCategoryBanner",
    label: "field.galleryCategoryBanner",
    type: "async-single-image",
    path: "banner",
    pw: 1000,
    ph: 500,
    disableUploadHistory: true,
    cols: 12,
  },
  // {
  //   label: "breadcrumb.brandCategory",
  //   icon: "AwardIcon",
  //   type: "divider",
  // },
  // {
  //   key: "brandCategoryBannerId",
  //   initKey: "brandCategoryBanner",
  //   label: "field.brandCategoryBanner",
  //   type: "async-single-image",
  //   path: "banner",
  //   pw: 1000,
  //   ph: 500,
  //   disableUploadHistory: true,
  //   cols: 12,
  // },
  // {
  //   label: "breadcrumb.business",
  //   icon: "BriefcaseIcon",
  //   type: "divider",
  // },
  // {
  //   key: "businessBannerId",
  //   initKey: "businessBanner",
  //   label: "field.businessBanner",
  //   type: "async-single-image",
  //   path: "banner",
  //   pw: 1000,
  //   ph: 500,
  //   disableUploadHistory: true,
  //   cols: 12,
  // },
  {
    label: "breadcrumb.article",
    icon: "BookOpenIcon",
    type: "divider",
  },
  {
    key: "articleBannerId",
    initKey: "articleBanner",
    label: "field.articleBanner",
    type: "async-single-image",
    path: "banner",
    pw: 1000,
    ph: 500,
    disableUploadHistory: true,
    cols: 12,
  },
  // {
  //   label: "breadcrumb.job",
  //   icon: "BriefcaseIcon",
  //   type: "divider",
  // },
  // {
  //   key: "jobBannerId",
  //   initKey: "jobBanner",
  //   label: "field.jobBanner",
  //   type: "async-single-image",
  //   path: "banner",
  //   pw: 1000,
  //   ph: 500,
  //   disableUploadHistory: true,
  //   cols: 12,
  // },
];
